import '../common/styles/destyle.scss';
import '../common/styles/globals.scss';
import '../common/styles/tailwind.css';
import { useState } from 'react';

import type { AppProps } from 'next/app';
import { Roboto, Roboto_Condensed } from 'next/font/google';

import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';

import { useProvideAuth } from '@hooks_common/useAuth';

import CommonHead from '@components_iqfu/public/common_head/CommonHead';
import FacebookPixel from '@components_iqfu/public/facebook_pixel/FacebookPixel';
import PageLoader from '@components_iqfu/public/page_loader/PageLoader';

import { TrackingCodes } from '@components_common/gtag/gtag';
import NotificationBar from '@components_common/notification_bar/NotificationBar';
import { NotificationText } from '@constants_iqfu';
import { authContext } from '@contexts_common/authContext';
import { notificationContext } from '@contexts_common/notificationContext';

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  variable: '--font-roboto',
  subsets: ['latin'],
});

const roboto_condensed = Roboto_Condensed({
  weight: ['300', '400', '700'],
  variable: '--font-roboto-condensed',
  subsets: ['latin'],
});

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  //require('../mocks');
}

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const auth = useProvideAuth();
  const [showNotificationBar, setNotificationDisplay] = useState<boolean>(false);
  const [status, setNotificationStatus] = useState<'success' | 'alert'>('alert');
  const [message, setNotificationMessage] = useState<string>(NotificationText.invalidValue);

  const needsTracking =
    process.env.NEXT_PUBLIC_APP_ENV === 'production_server' || process.env.NEXT_PUBLIC_APP_ENV === 'development_server';

  return (
    <>
      <CommonHead />
      {needsTracking && <TrackingCodes />}
      <CookiesProvider>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <authContext.Provider value={auth}>
              <notificationContext.Provider
                value={{ setNotificationDisplay, setNotificationMessage, setNotificationStatus }}
              >
                <FacebookPixel>
                  <div className={`${roboto.variable} ${roboto_condensed.variable}`}>
                    <NotificationBar
                      open={showNotificationBar}
                      toggle={(prevState: boolean) => setNotificationDisplay(!prevState)}
                      close={() => setNotificationDisplay(false)}
                      message={message}
                      status={status}
                    />
                    <PageLoader />
                    <Component {...pageProps} />
                  </div>
                </FacebookPixel>
              </notificationContext.Provider>
            </authContext.Provider>
          </QueryClientProvider>
        </RecoilRoot>
      </CookiesProvider>
    </>
  );
}

export default MyApp;
